import React, { Component , useEffect, useState } from 'react';
import {Button, Modal,ListGroup, Spinner, Col, Container, Row } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import Rf from '../useful/raveFeed'

import FadeIn from 'react-fade-in';
import './vote.css'
const axios = require('axios').default;

class vote extends Component {

    constructor(props){
        super(props);
        this.state = {
            artists : [],
            modal: false,
            inLoad: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleLoading = this.handleLoading.bind(this);
    }

    handleLoading() {
      this.setState({ inLoad: true });
    }

    handleChange(row, value) {
        let tmpArray = this.state.artists
        tmpArray[row] = value.target.value.toUpperCase()
        this.setState({
            artists: tmpArray,
        })
    }

    onSubmit(event){
        event.preventDefault();
    }
    render() {
      
        return (
                <FadeIn>
                <form className="voteForm">
                    <input type="text" className="voteInput" placeholder="Artiste 1" onChange={(value) => this.handleChange(0,value)}/>
                    <input type="text" className="voteInput" placeholder="Artiste 2"onChange={(value) => this.handleChange(1,value)}/>
                    <input type="text" className="voteInput" placeholder="Artiste 3" onChange={(value) => this.handleChange(2,value)}/>
                    <input type="text" className="voteInput" placeholder="Artiste 4" onChange={(value) => this.handleChange(3,value)}/>
                    <input type="text" className="voteInput" placeholder="Artiste 5" onChange={(value) => this.handleChange(4,value)}/>
                    <Example artists = {this.state.artists} loader = {this.state.inLoad} load = {this.handleLoading}></Example>
                </form>
                </FadeIn>
        );
    }
}

export default vote;
function Example(props) {
    var  voted = false;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let history = useHistory();
    const sendVote = () => {
      
      props.load()
      axios.post('https://toplegendsapi.ravefeed.com/newVote', {
        artists : props.artists
      })
      .then(function (res) {
        history.push("/Thanks");
      })
      .catch((err) => {
        console.log(err)
      })
    }
    const testReq = () => {
      axios.get(`https://toplegendsapi.ravefeed.com/`)
      .then(res => {
      })
    }
    return (
      <>

<Button variant="primary" onClick={handleShow} className="voteButton">
        Voter
      </Button>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <Modal.Title>Mes votes</Modal.Title>
          </Modal.Header>
          <div className="loader">
          </div>
          {!props.loader && (<div>
              <Modal.Body>
            <ListGroup variant="flush">
              <ListGroup.Item><span>1. </span><span>{props.artists[0]}</span></ListGroup.Item>
              <ListGroup.Item><span>2. </span><span>{props.artists[1]}</span></ListGroup.Item>
              <ListGroup.Item><span>3. </span><span>{props.artists[2]}</span></ListGroup.Item>
              <ListGroup.Item><span>4. </span><span>{props.artists[3]}</span></ListGroup.Item>
              <ListGroup.Item><span>5. </span><span>{props.artists[4]}</span></ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendVote} className="voteButton">
            Envoyer !
          </Button>
        </Modal.Footer>
              </div>)
          }
        {props.loader && (<div>
              <Modal.Body>
                <div className="loader">
                    <Spinner animation="grow" variant="light" />
                    <Spinner animation="grow" variant="light" />
                    <Spinner animation="grow" variant="light" />
                </div>
        </Modal.Body>
        <Modal.Footer>
        <p>Rave Feed</p>
        </Modal.Footer>
              </div>)
          }

        </Modal>
      </>
    );
  }