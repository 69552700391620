import React, { Component } from 'react';
import TextTransition, { presets } from "react-text-transition";

class ravefeedAnimated extends Component {
  cons

    render() {
        return (
            <div>
                <TextAnimated/>
            </div>
        );
    }
}

export default ravefeedAnimated;

var TextAnimated = () => {
  const [index, setIndex] = React.useState(0);

const TEXTS = [
  "Rave Feed",
];
 
  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      300000 // every 3 seconds
    );
  });
 
  return (
    <h1>
      <TextTransition
        text={ TEXTS[index % TEXTS.length] }
        springConfig={ presets.wobbly }
      />
    </h1>
  );
};