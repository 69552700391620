import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

  import './navBar.css'

class navBar extends Component {
    render() {
        return (
            <div className="titre">
                <p>
                    <p className="mainTitle">TOP LEGENDS</p>
                    <p className="rightJustify">
                    <span id="By">by </span><span id="raveFeedTop">Rave Feed</span>
                </p>
                </p>
            </div>
        );
    }
}

export default navBar;