import React, { Component } from 'react';


import bg from '../imgs/5cb7f306a334eWEB2-181021-000539-amf-jt_pkz-7833.jpeg'
class backgroundImagesRight extends Component {

    render() {
        return (
            <div className="bgImgRight">
                <Builder img="" count={3} className='bgImageRight'></Builder>
            </div>
        );
    }
}

export default backgroundImagesRight;


var Builder = (props) => {
    var img = bg;
    var count = props.count;
    var tmpImageContainer = [];
    for (var i = 1; i<=count; i++){
        var tmpImg = (<img src={img} key={i} className={props.className} style={{ right:`calc(200px - ${i*30}px)`, bottom: `calc(8vh - ${i*30}px)`,opacity: `${i/count}` }}/>);
        tmpImageContainer.push(tmpImg)
    }
    return tmpImageContainer;
}