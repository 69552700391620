import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import VoteChildren from './vote'

class voteMaster extends Component {
    render() {
        return (
            <div>
                <Col xl={9} sm={8} xs={10} className="voteContainer">
                    <Col xl={12}>
                                <VoteChildren/>
                    </Col>
                </Col>
            </div>
        );
    }
}

export default voteMaster;