import React from 'react';
import './App.css';

import NavBar from './navBar'
import BackgroundImages from './useful/backgroundImgBuilder'
import BackgroundImagesRight from './useful/backgroundImagesRight'

import Vote from './vote/voteMaster'
import Thanks from './thanks/thanks'
import Welcome from './welcome/welcome'



import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <NavBar></NavBar>
          {/* <RaveFeed></RaveFeed> */}
          {/* <Nav></Nav> */}
            <BackgroundImages/>
            <BackgroundImagesRight/>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/">
                <Welcome />
            </Route>
            <Route exact path="/Vote">
              <Vote />
            </Route>
            <Route path="/Thanks">
              <Thanks/>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
