import React, { Component } from 'react';
import { useHistory } from "react-router-dom";

import { Row, Col, Container, Button } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

import {
    Link
  } from "react-router-dom";

import './welcome.css'

class welcome extends Component {

    render() {
        return (
        <FadeIn>
            <Container>
                <Row className="justify-content-md-center">
                    <Col className="welcome-col" xl={7}>
                    <p>
                        Année particulière, classement particulier...
                        <br/>
                        <br/>
                        Bienvenue dans la première édition du <strong>TOP LEGENDS</strong> by Rave Feed ! 
                        <br/>
                        <br/>
                        <br/>
                        Votez pour vos 5 artistes de musique électronique favoris de <strong>TOUS LES TEMPS</strong> et <strong>TOUS STYLES CONFONDUS</strong> ! 🏆
                        <br/>
                        <br/>
                        <br/>
                        <p>Résultats le 6 décembre où nous vous annoncerons le TOP 50 🎧</p>
                    </p>
                    <div className="voteButtonDiv">
                        <Link to="/Vote" id="voteButton">Voter !</Link>
                    </div>
                    
                    </Col>
                </Row>
            </Container>
        </FadeIn>
        );
    }
}

export default welcome;