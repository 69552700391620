import React, { Component } from 'react';

import bg from '../imgs/amsterdam-music-festival-dj-box-view-fireworks.jpeg'
class backgroundImgBuilder extends Component {
    render() {
        return (
            <div className="bgImgLeft">
                <Builder img="/Users/alexendreobli/Desktop/amsterdam-music-festival-dj-box-view-fireworks.jpeg" count={3} className='bgImageLeft'></Builder>
            </div>
        );
    }
}

export default backgroundImgBuilder;


var Builder = (props) => {
    var img = bg;
    var count = props.count;
    var tmpImageContainer = [];
    for (var i = 1; i<=count; i++){
        var tmpImg = (<img src={img} key={i} className={props.className} style={{ left:`calc(200px - ${i*30}px)`, top: `calc(15vh - ${i*30}px)`,opacity: `${i/count}` }}/>);
        tmpImageContainer.push(tmpImg)
    }
    return tmpImageContainer;
}