import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import FadeIn from 'react-fade-in';

import RavefeedAnimated from '../useful/ravefeedAnimated'

import './thanks.css'

class thanks extends Component {
    render() {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col xl={6} sm={12} md={9} lg={6} className="thanks-div">
                        <FadeIn>
                            <p className="thanks-title">
                                Merci pour ton vote !
                            </p >
                            <FadeIn>
                                <p className="thanks-text text-thanks">
                                    Les résulats arrivent le 6 décembre
                                </p>
                                <hr></hr>
                            </FadeIn>
                        </FadeIn>
                        <FadeIn>

                        <FadeIn>
                                    <p className="thanks-subTitle text-thanks"> Retrouve nous sur tous tes réseaux ! </p>
                        </FadeIn>
                            <div>
                                <ul class="social-networks spin-icon">
                                    <li><a href="https://twitter.com/ravefeed" class="icon-twitter icon"></a></li>
                                    <li><a href="https://www.facebook.com/ravefeed" class="icon-facebook icon"></a></li>
                                    <li><a href="https://instagram.com/ravefeedfr" class="icon-instagram icon"></a></li>
                                </ul>

                                <a href="https://www.facebook.com/sharer/sharer.php?u=toplegends.ravefeed.com" target="_blank" className="shareLink">
                                 Invite tes amis à voter !
                                </a>
                            </div>
                        </FadeIn>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default thanks;