import React, { Component } from 'react';
import './raveFeed.css'

class raveFeed extends Component {
    render() {
        return (
            <div className="raveFeedLogo">
                <p className="raveFeedName">
                    Rave Feed
                </p>
            </div>
        );
    }
}

export default raveFeed;